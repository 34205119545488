import React, { useRef } from "react";
import './FirstChat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import FileInput from '../FileInput/FileInput';

const FirstChat = ({ onFileSelect, onFileRemove, onUpload }) => {
    const fileInputRef = useRef(); // Referência ao FileInput

    return (
        <div className="container-firstchat">
            <span className="txt-externo">
                Para começar o chat, insira um arquivo abaixo.
            </span>
            <button
                className="container"
                onClick={() => fileInputRef.current.onChooseFile()} // Dispara o FileInput
            >
                <FontAwesomeIcon icon={faFilePdf} size="3x" className="pdf-icon" />
                <span className="txt-interno">Escolha um arquivo ou arraste-o para cá.</span>
            </button>

            {/* O FileInput pode ser usado em qualquer lugar da aplicação */}
            <FileInput
                ref={fileInputRef} // Conecta o FileInput à referência
                onFileSelect={onFileSelect}
                onFileRemove={onFileRemove}
                onUpload={onUpload}
            />
        </div>
    );
};

export default FirstChat;
