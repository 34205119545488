import React from 'react';
import '../styles/Perfil.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

const Perfil = () => {
    return (
        <div className='main-container-perfil'>
            <div className='container-perfil'>
                <div className='container-esquerdo'>
                    <line className='hr-esq-perfil' />
                    <button className='btn-esq-perfil'>Perfil</button>
                    <line className='hr-esq-perfil' />
                    <button className='btn-esq-perfil'>Segurança</button>
                    <line className='hr-esq-perfil' />
                    <button className='btn-esq-perfil'>Configurações</button>
                    <line className='hr-esq-perfil' />
                    <button className='btn-esq-perfil'>Privacidade</button>
                    <line className='hr-esq-perfil' />
                </div>
                <div className='container-direito'>
                    <div className='container-img-perfil'>
                        <FontAwesomeIcon className='user-chatimg' icon={faCircleUser} size="6x" />
                    </div>
                    <div className='container-txt-perfil'>
                        <span className='txt-perfil'>Fulano da Silva e Silva</span>
                        <span className='txt-perfil'>fulanodasilva@gmail.com</span>
                        <span className='txt-perfil'>Empresa Ciclano Ltda.</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Perfil;
