import React, { useState, useEffect } from "react";
import './Navbar.css';
import logo from '../../assets/jimmy.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faBars } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate(); // Hook do React Router para navegação
    const location = useLocation(); // Hook do React Router para obter a localização atual
    
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Atualiza o estado do menu com base no tamanho da tela
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 800) {
                setIsOpen(false); // Fecha o menu quando a tela é maior que 500px
            }
        };

        // Adiciona e remove o listener de redimensionamento
        window.addEventListener('resize', handleResize);
        handleResize(); // Verifica o tamanho inicial

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Função para redirecionar para a rota correspondente
    const handleNavigation = (route) => {
        navigate(route); // Navega para a página desejada
    };

    return (
        <div className="navbar">
            <img src={logo} alt="" className="logo" />
            <button className="menu-toggle" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faBars} size="2x" />
            </button>
            <ul className={`menu ${isOpen ? 'open' : ''}`}>
                <li className={location.pathname === '/' ? 'active' : ''} onClick={() => handleNavigation('/')}>Início</li>
                <li className={location.pathname === '/jimmy' ? 'active' : ''} onClick={() => handleNavigation('/jimmy')}>Jimmy</li>
                <li className={location.pathname === '/calendario' ? 'active' : ''} onClick={() => handleNavigation('/calendario')}>Calendário</li>
                <li className={location.pathname === '/jurisprudencia' ? 'active' : ''} onClick={() => handleNavigation('/jurisprudencia')}>Jurisprudência</li>
                <li className={location.pathname === '/perfil' ? 'active' : ''} onClick={() => handleNavigation('/perfil')}>Perfil</li>
            </ul>
            <FontAwesomeIcon icon={faCircleUser} size='3x' className="user" />
        </div>
    );
}

export default Navbar;
