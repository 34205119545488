import React, { useState, useEffect, useRef } from 'react';
import '../styles/Jimmy.css';
import FileInput from '../components/FileInput/FileInput';
import axios from 'axios';
import logoj from '../assets/logo j.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faGear, faSquarePlus, faCircleUser, faChevronRight, faFileLines, faAngleUp, faQuestion } from '@fortawesome/free-solid-svg-icons';
import FirstChat from '../components/FirstChat/FirstChat';
import ConfigChat from '../components/ConfigChat/ConfigChat'; // Importe o componente ConfigChat


function Jimmy() {
    const [mensagem, setMessage] = useState('');
    const [mensagens, setMessages] = useState([]);
    const [iaMessageUpdated, setIaMessageUpdated] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [isFirstChatVisible, setIsFirstChatVisible] = useState(true);
    const chatEndRef = useRef(null);
    const fileInputRef = useRef();
    const [savedChats, setSavedChats] = useState([]);
    const [currentFileName, setCurrentFileName] = useState(null); // Estado para armazenar o nome do arquivo
    const [isCor, setCor] = useState(true);
    const [currentChat, setCurrentChat] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false); // Estado para controlar o envio da mensagem
    const [isConfigVisible, setIsConfigVisible] = useState(false);
    const [isChatSaved, setIsChatSaved] = useState(false); // Estado para verificar se o chat já foi salvo
    const [isLoadingChat, setIsLoadingChat] = useState(false); // Estado para controlar o carregamento do chat

    const openConfigChat = () => {
        setIsConfigVisible(true); // Mostra o ConfigChat
    };

    const closeConfigChat = () => {
        setIsConfigVisible(false); // Fecha o ConfigChat
    };

    const handleFileSelect = (file) => {
        console.log("Arquivo selecionado no App.js:", file);

    };

    const handleFileRemove = () => {
        console.log("Arquivo removido no App.js");
        //setIsFirstChatVisible(true); // Volta a exibir o FirstChat se o arquivo for removido
    };

    const handleUpload = () => {
        console.log("Upload completo no App.js");
        setIsFirstChatVisible(false); // Oculta o FirstChat após o upload
    };

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const sendMessage = async (msg = mensagem) => {
        if (msg.trim() === '' || isGenerating) return; // Impede o envio se estiver gerando resposta

        setMessages(prevMessages => [...prevMessages, { text: msg, type: 'user' }]);
        setMessage('');
        setMessages(prevMessages => [...prevMessages, { text: 'Gerando...', type: 'ia' }]);
        setIsGenerating(true); // Indica que a geração está em andamento

        try {
            const response = await axios.post('https://api.macrodev.ai/sendMessage', { mensagem: msg });
            setMessages(prevMessages => {
                const updatedMessages = [...prevMessages];
                updatedMessages[updatedMessages.length - 1] = { text: response.data.resposta_ia, type: 'ia' };
                return updatedMessages;
            });
            setIaMessageUpdated(true); // Marca a mensagem da IA como atualizada

        } catch (error) {
            setMessages(prevMessages => {
                const updatedMessages = [...prevMessages];
                updatedMessages[updatedMessages.length - 1] = { text: 'Erro ao gerar resposta.', type: 'ia' };
                return updatedMessages;
            });
            setIaMessageUpdated(true); // Marca a mensagem da IA como atualizada
        } finally {
            setIsGenerating(false); // Libera o envio quando a resposta for gerada
        }
    };

    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [mensagens]);

    useEffect(() => {
        fetchSavedChats(); // Carrega os chats salvos ao carregar o componente
    }, []);

    // Efeito para salvar o chat apenas se a mensagem da IA não for "Gerando..."
    useEffect(() => {
        if (iaMessageUpdated && mensagens.length > 0 && mensagens[mensagens.length - 1].type === 'ia' && mensagens[mensagens.length - 1].text !== 'Gerando...') {
            saveChat();
            setIaMessageUpdated(false); // Reseta a flag após salvar
        }
    }, [iaMessageUpdated, mensagens]);

    useEffect(() => {
        fetchSavedChats(); // Carrega os chats salvos ao carregar o componente
    }, [mensagens]);

    const fetchSavedChats = async () => {
        try {
            const response = await axios.get('https://api.macrodev.ai/list-chats');
            setSavedChats(response.data.chats);
        } catch (error) {
            console.error('Erro ao carregar chats salvos:', error);
        }
    };

    const saveChat = async () => {
        if (mensagens.length === 0) {
            console.error('Não é possível salvar um chat vazio.');
            return; // Impede a execução se não houver mensagens
        }

        let fileName = currentFileName;

        // Se o chat não foi salvo antes, crie um nome de arquivo padrão
        if (!isChatSaved) {
            if (!fileName) {
                // Cria um nome de arquivo padrão
                const folderNumber = savedChats.length + 1;
                fileName = `chat_${folderNumber.toString().padStart(2, '0')}.txt`;
            }
            setCurrentFileName(fileName);
        }

        const chatData = mensagens.map((msg) => `${msg.type}: ${msg.text}`).join('\n');

        try {
            await axios.post('https://api.macrodev.ai/save-chat', {
                mensagem: chatData,
                file_name: fileName // Usa o nome do arquivo atualizado
            });
            console.info('Chat salvo com sucesso!');
            setIsChatSaved(true); // Marca o chat como salvo
            await fetchSavedChats();
        } catch (error) {
            console.error('Erro ao salvar o chat:', error);
        }
    };

    const loadChat = async (fileName) => {
        setIsLoadingChat(true); // Inicia o estado de carregamento
        try {
            const response = await axios.get(`https://api.macrodev.ai/load-chat/${fileName}`);
            const content = response.data.content;
            const messages = [];
            const lines = content.split('\n');
            let currentMessage = '';

            lines.forEach((line) => {
                if (line.startsWith('user: ') || line.startsWith('ia: ')) {
                    if (currentMessage) {
                        const [type, ...textArray] = currentMessage.split(': ');
                        messages.push({ type, text: textArray.join(': ') });
                    }
                    currentMessage = line;
                } else {
                    currentMessage += `\n${line}`;
                }
            });

            if (currentMessage) {
                const [type, ...textArray] = currentMessage.split(': ');
                messages.push({ type, text: textArray.join(': ') });
            }

            setMessages(messages);
            setIsFirstChatVisible(false);
            setCurrentFileName(fileName);
            setCurrentChat(fileName); // Atualize o chat atual

        } catch (error) {
            console.error('Erro ao carregar o chat:', error);
        }
        finally {
            setIsLoadingChat(false); // Finaliza o estado de carregamento
        }
    };

    const cores = () => {
        setCor(!isCor);
    };

    const newChat = async () => {
        setIsFirstChatVisible(true);
        setMessages([]);
        setCurrentFileName(null);
        setCurrentChat(null); // Reseta o chat atual
    };

    const deleteChat = async (fileName) => {
        try {
            await axios.post('https://api.macrodev.ai/delete-chat', { file_name: fileName });
            console.info(`Chat ${fileName} deleted successfully!`);
            await fetchSavedChats(); // Atualiza a lista de chats após a exclusão
        } catch (error) {
            console.error('Erro ao apagar chat:', error);
        }
    };

    return (
        <div className={`App ${isCor ? 'theme-alternate' : 'theme-default'}`}>
            <div className="undernavbar">
                <div className="sidebar-container">
                    <div className={`sidebar ${isSidebarVisible ? 'expanded' : 'collapsed'}`}>
                        <div className="upperside">
                            {isSidebarVisible && (
                                <div className="uppersidebottom">
                                    <span className='chats-recentes'>Chats recentes</span>
                                    <hr className='hr hrcompleto' />
                                    <div className='container-chat-load'>
                                        {savedChats.map((chat, index) => (
                                            <div key={index} className="container-btn-hr">
                                                <div className="container-btn">
                                                    <button
                                                        onClick={() => loadChat(chat)}
                                                        className={`btn-chat-load ${currentChat === chat ? 'active-chat' : ''}`} // Classe condicional
                                                        disabled={isGenerating || isLoadingChat}>
                                                        {chat.replace('.txt', '')}
                                                    </button>
                                                    <button className="btn-chat-options" onClick={() => deleteChat(chat)}>
                                                        <FontAwesomeIcon icon={faTimes} size="2x" />
                                                    </button>
                                                </div>
                                                <hr className="hr hr-chat-load" />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="lowerside">
                            <hr className='hr hrcompleto' />
                            <button title='novo chat' className="btn btn-side-new-chat" onClick={newChat}>
                                <FontAwesomeIcon icon={faSquarePlus} size='2x' />
                                {isSidebarVisible && <span className="btn-text">Novo chat</span>}
                            </button>
                            <hr className='hr hrparcial' />
                            <div className='lowerbtn-conteiner'>
                                <button title='Configurações' className="btn btn-side" onClick={openConfigChat}><FontAwesomeIcon icon={faGear} size='2x' /></button>
                                <button title='Ajuda' className="btn btn-side-ajuda" ><FontAwesomeIcon icon={faQuestion} size='2x' /></button>
                            </div>
                        </div>
                    </div>

                    <button className="btn btn-recolhesidebar" onClick={toggleSidebar}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
                <div className="main">
                    {isConfigVisible && <ConfigChat onClose={closeConfigChat} />}
                    {isLoadingChat ? ( // Verifica se o chat está carregando
                        <div className="loading-indicator">
                            <p>Carregando...</p>
                        </div>
                    ) : isFirstChatVisible ? ( // Caso contrário, verifica se o FirstChat está visível
                        <FirstChat
                            onFileSelect={handleFileSelect}
                            onFileRemove={handleFileRemove}
                            onUpload={handleUpload}
                        />
                    ) : (
                        <>

                            <div className='chats'>
                                {mensagens.map((msg, index) => (
                                    <div className={`chat ${msg.type === 'ia' ? 'chat-ia' : 'chat-user'}`} key={index}>
                                        {msg.type === 'ia' ? (
                                            <img className='ia-chatimg' src={logoj} />
                                        ) : (
                                            <FontAwesomeIcon className='user-chatimg' icon={faCircleUser} size="3x" />
                                        )}
                                        <p className='txt'>{msg.text}</p>
                                    </div>
                                ))}
                                <div ref={chatEndRef}></div>
                            </div>
                            <div className='chatfooter'>
                                <div className='sugest-conteiner'>
                                    <button className='btn-sugest'
                                        onClick={() => sendMessage('Resuma o documento')}
                                        disabled={isGenerating}>
                                        <span className='txt-sugest'>Resuma o documento</span>
                                    </button>
                                    <button className='btn-sugest'
                                        onClick={() => sendMessage('Esse documento está de acordo com as normas brasileiras?')}
                                        disabled={isGenerating}>
                                        <span className='txt-sugest'>Esse documento está de acordo com as normas brasileiras?</span>
                                    </button>
                                </div>
                                <div className="input-conteiner">
                                    <FileInput
                                        ref={fileInputRef}
                                        onFileSelect={handleFileSelect}
                                        onFileRemove={handleFileRemove}
                                        onUpload={handleUpload}
                                        additionalClass="custom-file-input"
                                    />
                                    <button onClick={() => fileInputRef.current.onChooseFile()} className="btn btn-input-left" disabled={isGenerating}>
                                        <FontAwesomeIcon icon={faFileLines} size='2x' />
                                    </button>
                                    <input
                                        className="textinput"
                                        type="text"
                                        placeholder="Faça uma pergunta ou um comando"
                                        value={mensagem}
                                        onChange={(e) => setMessage(e.target.value)}
                                        disabled={isGenerating} // Desativa o input enquanto gera a resposta
                                    />
                                    <button
                                        className="btn btn-input-right"
                                        onClick={() => sendMessage()}
                                        disabled={isGenerating} // Desativa o botão enquanto gera a resposta
                                    >
                                        <FontAwesomeIcon icon={faAngleUp} />
                                    </button>
                                </div>
                                <p className="chatfooter-paragraph">Jimmy não produz respostas incorretas</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Jimmy;
