// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-file {
  cursor: pointer;
  margin: 5px 10px;
  border: 2px solid var(--borda-verde);
  border-radius: 10px;
  padding: 5px;
  background: var(--fill-verde);
}

.btn-file:hover {
  background: var(--borda-verde);
}

.btn-file:hover .btn-file-txt {
  color: var(--pagina-externa);
}

.selected-file {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--borda-verde);
  border-radius: 20px;
  padding: 10px 10px 3px 10px;
  margin-top: 10px;
  background: var(--fill-verde);
  flex-direction: column;
  text-align: center;
  max-width: 500px;
}

.btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.custom-file-input {
  border: 2px solid var(--borda-verde);
  border-radius: 20px;
  padding: 10px 10px 3px 10px;
  background: var(--fill-verde);
  position: fixed;
  bottom: 90px;
  z-index: 1000;
  padding: 10px;

}`, "",{"version":3,"sources":["webpack://./src/components/FileInput/FileInput.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,oCAAoC;EACpC,mBAAmB;EACnB,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oCAAoC;EACpC,mBAAmB;EACnB,2BAA2B;EAC3B,gBAAgB;EAChB,6BAA6B;EAC7B,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,2BAA2B;EAC3B,6BAA6B;EAC7B,eAAe;EACf,YAAY;EACZ,aAAa;EACb,aAAa;;AAEf","sourcesContent":[".btn-file {\n  cursor: pointer;\n  margin: 5px 10px;\n  border: 2px solid var(--borda-verde);\n  border-radius: 10px;\n  padding: 5px;\n  background: var(--fill-verde);\n}\n\n.btn-file:hover {\n  background: var(--borda-verde);\n}\n\n.btn-file:hover .btn-file-txt {\n  color: var(--pagina-externa);\n}\n\n.selected-file {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 2px solid var(--borda-verde);\n  border-radius: 20px;\n  padding: 10px 10px 3px 10px;\n  margin-top: 10px;\n  background: var(--fill-verde);\n  flex-direction: column;\n  text-align: center;\n  max-width: 500px;\n}\n\n.btn-container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n\n.custom-file-input {\n  border: 2px solid var(--borda-verde);\n  border-radius: 20px;\n  padding: 10px 10px 3px 10px;\n  background: var(--fill-verde);\n  position: fixed;\n  bottom: 90px;\n  z-index: 1000;\n  padding: 10px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
