// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --sombra: drop-shadow(0px 3px 3px #747474);
  --bordas: #BEBEBE;
  --fonte: #5D5D5D;
  --fill-verde: #A6DBCB;
  --borda-verde: #007A5F;
  --pagina-interna: #FAFAFA;
  --pagina-externa: #FFFFFF;
  --destaque-letras: #00C259;
  --hover-verde: #00C259;
  --hover-cinza: #dadada;
}

.navbar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1002;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;EACrB,sBAAsB;EACtB,yBAAyB;EACzB,yBAAyB;EACzB,0BAA0B;EAC1B,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,aAAa;AACf","sourcesContent":[":root {\n  --sombra: drop-shadow(0px 3px 3px #747474);\n  --bordas: #BEBEBE;\n  --fonte: #5D5D5D;\n  --fill-verde: #A6DBCB;\n  --borda-verde: #007A5F;\n  --pagina-interna: #FAFAFA;\n  --pagina-externa: #FFFFFF;\n  --destaque-letras: #00C259;\n  --hover-verde: #00C259;\n  --hover-cinza: #dadada;\n}\n\n.navbar-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  z-index: 1002;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
