import React from "react";
import './ConfigChat.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const resetia = async () => {
    try {
        await axios.post('https://api.macrodev.ai/reset');
    } catch (error) {
        console.error('Error resetting IA:', error);
    }
};

const apagapdf = async () => {
    try {
        await axios.post('https://api.macrodev.ai/apagapdf');
    } catch (error) {
        console.error('Error deleting PDF:', error);
    }
};

const coisinha = async () => {
    try {
        await axios.post('https://api.macrodev.ai/coisinha');
    } catch (error) {
        console.error('Error with coisinha:', error);
    }
};

const ConfigChat = ({ onClose }) => {
    return (
        <div className="configchat-overlay">
            <div className="configchat-container">
                <div className="container-upper">
                    <span>Configurações do chat</span>
                    <button className="configchat-close-btn" onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className="container-bottom">
                    <div className="container-bottom-left">
                        <div className="container-btn-left">
                            <button className="btn-left">Configurações de desenvolvedor</button>
                        </div>
                    </div>
                    <hr className="divisor-vertical" />
                    <div className="container-bottom-right">
                        <div className="container-btn-right">
                            <span>Resetar IA</span>
                            <button className="btn-right" onClick={resetia}>Resetar</button>
                        </div>
                        <hr className="divisor-horizontal" />
                        <div className="container-btn-right">
                            <span>Checar IA</span>
                            <button className="btn-right" onClick={coisinha}>Checar</button>
                        </div>
                        <hr className="divisor-horizontal" />
                        <div className="container-btn-right">
                            <span>Apagar documentos</span>
                            <button className="btn-right" onClick={apagapdf}>Apagar</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfigChat
