import React, { useRef, useState } from "react";
import './FileInput.css';
import axios from 'axios';

const FileInput = React.forwardRef(({ onFileSelect, onFileRemove, onUpload, isActive, additionalClass }, ref) => {
  const inputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const handleOnChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        setSelectedFile(file);
        setErrorMessage('');
        if (onFileSelect) {
          console.log("Chamando onFileSelect com o arquivo:", file);
          onFileSelect(file);
        }
      } else {
        setErrorMessage('Por favor, selecione um arquivo PDF.');
        setSelectedFile(null);
      }
    } else {
      setSelectedFile(null);
    }
  };

  const onChooseFile = () => {
    inputRef.current.click();
  };

  React.useImperativeHandle(ref, () => ({
    onChooseFile
  }));

  const removeFile = () => {
    console.log("Chamando removeFile");
    setSelectedFile(null);
    if (onFileRemove) onFileRemove();
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setErrorMessage('Nenhum arquivo selecionado.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    setIsUploading(true); // Inicia o upload e desativa os botões

    try {
      const response = await axios.post('https://api.macrodev.ai/pdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      alert(response.data.feedback || 'Upload bem sucedido!');
      setSelectedFile(null);
      if (onUpload) {
        console.log("Chamando onUpload após o upload");
        onUpload();
      }
    } catch (error) {
      console.error('Erro ao enviar arquivo:', error);
      alert('Erro ao enviar o arquivo. Tente novamente.');
    }
    finally {
      setIsUploading(false); // Reativa os botões após o término do upload
    }
  };

  return (
    <div className="file">
      <input
        type="file"
        accept=".pdf"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: "none" }}
      />

      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {selectedFile && (
        <div className={`selected-file ${isActive ? 'file-active' : ''} ${additionalClass || ''}`}>
          <p>{selectedFile.name}</p>
          <div className="btn-container">

            <button disabled={isUploading} onClick={removeFile} className="btn-file">
              <span className="btn-file-txt">Cancelar</span>
            </button>

            <button disabled={isUploading} onClick={handleUpload} className="btn-file">
              <span className="btn-file-txt">Enviar</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
});

export default FileInput;
