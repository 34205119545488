import React from 'react';
import '../styles/Jurisprudencia.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass,faFileLines } from '@fortawesome/free-solid-svg-icons';

const Jurisprudencia = () => {
    return (
        <div className='main-container-jurisprudencia'>
            <div className='container-jurisprudencia'>
                <div className='container-input-pesquisa-jurisprudencia'>
                    <button className='icone-input-file' >
                        <FontAwesomeIcon icon={faFileLines} size='2x' />
                    </button>
                    <input type="text"
                        placeholder="Pesquisa" className='input-pesquisa-jurisprudencia'>
                    </input>
                    <button className='icone-pesquisa' >
                        <FontAwesomeIcon icon={faMagnifyingGlass} size='2x' />
                    </button>
                </div>
                <div className='resultado'>
                </div>
            </div>
        </div>
    );
};

export default Jurisprudencia;
