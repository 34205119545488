import React, { useState } from 'react';
import '../styles/Calendario.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const Calendario = () => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    // Gerar os dias do mês (simplificado)
    const daysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    // Alternar entre meses
    const nextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    };

    const previousMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    };

    const renderDays = () => {
        const days = [];
        const totalDays = daysInMonth(currentMonth, currentYear);

        for (let i = 1; i <= totalDays; i++) {
            days.push(<div className='dia' key={i}>{i}</div>);
        }

        return days;
    };

    const monthNames = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    return (
        <div className='main-container-calendario'>
            <div className={`container-sidebar-calendario ${isSidebarVisible ? 'expanded' : 'collapsed'}`}>
                <div className='titulo-sidebar-calendario'>
                    <span>Eventos marcados</span>
                </div>
                <div className='lista-eventos'>
                <span className='txt-interno'>Reunião com Fulano - 01/03</span>
                    <line className='divisor-interno-hp'></line>
                    <span className='txt-interno'>Reunião com Fulano - 01/03</span>
                    <line className='divisor-interno-hp'></line>
                    <span className='txt-interno'>Reunião com Fulano - 01/03</span>
                </div>
            </div>

            <button className="btn btn-recolhesidebar-calendario" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={faChevronRight} />
            </button>

            <div className='container-calendario'>
                <div className='calendario'>
                    <div className='container-mes'>
                        <button className='btn-nav' onClick={previousMonth}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <span className='mes'>{monthNames[currentMonth]} {currentYear}</span>
                        <button className='btn-nav' onClick={nextMonth}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>

                    <div className='container-semana-dia'>
                        <div className='container-semana'>
                            <div className='semana'>Dom</div>
                            <div className='semana'>Seg</div>
                            <div className='semana'>Ter</div>
                            <div className='semana'>Qua</div>
                            <div className='semana'>Qui</div>
                            <div className='semana'>Sex</div>
                            <div className='semana'>Sáb</div>
                        </div>
                        <div className='container-dia'>
                            {renderDays()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calendario;
