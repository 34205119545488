// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
   font-family: sans-serif; 
}
.container-firstchat{
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza horizontalmente */
    
}
.container{
    background: #A6DBCB;
    width: 354px;
    height: 236px;
    border-radius: 46px;
    border: 2px solid #007A5F;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza horizontalmente */
    justify-content: center;
    cursor: pointer;
    filter: var(--sombra);
}
.container:hover{
    background: #007A5F;
}
.container:hover .txt-interno,
.container:hover .pdf-icon {
    color: white; /* Muda o texto para branco no hover */
}
.txt-interno{
    color: #007A5F;
    font-size: 16px;
}
.txt-externo{
    color: #5E5E5E;
    font-size: 30px;
    margin-bottom: 50px;
    margin-top: 50px;
    text-align: center;
}
.pdf-icon{
    color: #007A5F;
    margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/FirstChat/FirstChat.css"],"names":[],"mappings":"AAAA;GACG,uBAAuB;AAC1B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAE,+BAA+B;;AAExD;AACA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAE,+BAA+B;IACpD,uBAAuB;IACvB,eAAe;IACf,qBAAqB;AACzB;AACA;IACI,mBAAmB;AACvB;AACA;;IAEI,YAAY,EAAE,sCAAsC;AACxD;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,mBAAmB;AACvB","sourcesContent":["*{\n   font-family: sans-serif; \n}\n.container-firstchat{\n    display: flex;\n    flex-direction: column;\n    align-items: center; /* Centraliza horizontalmente */\n    \n}\n.container{\n    background: #A6DBCB;\n    width: 354px;\n    height: 236px;\n    border-radius: 46px;\n    border: 2px solid #007A5F;\n    display: flex;\n    flex-direction: column;\n    align-items: center; /* Centraliza horizontalmente */\n    justify-content: center;\n    cursor: pointer;\n    filter: var(--sombra);\n}\n.container:hover{\n    background: #007A5F;\n}\n.container:hover .txt-interno,\n.container:hover .pdf-icon {\n    color: white; /* Muda o texto para branco no hover */\n}\n.txt-interno{\n    color: #007A5F;\n    font-size: 16px;\n}\n.txt-externo{\n    color: #5E5E5E;\n    font-size: 30px;\n    margin-bottom: 50px;\n    margin-top: 50px;\n    text-align: center;\n}\n.pdf-icon{\n    color: #007A5F;\n    margin-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
