import React from 'react';
import '../styles/HomePage.css';


const HomePage = () => {
    return (
        <div className='main-container-homepage'>
            <div className='home-container'>
                <span className='txt-superior'>Eventos agendados próximos</span>
                <div className='container-detalhes'>
                    <span className='txt-interno'>Reunião com Fulano - 01/03 - Em tal Lugar</span>
                    <line className='divisor-interno-hp'></line>
                    <span className='txt-interno'>Reunião com Fulano - 01/03 - Em tal Lugar</span>
                    <line className='divisor-interno-hp'></line>
                    <span className='txt-interno'>Reunião com Fulano - 01/03 - Em tal Lugar</span>
                </div>
                <span className='txt-inferior'>Ir para Calendário</span>
            </div>
            <div className='home-container'>
                <span className='txt-superior'>Última pesquisa no Jurisearch</span>
                <div className='container-detalhes-jurisearch'>
                    <div className='txt-interno-jurisearch'>
                        <p><strong>Consulta realizada:</strong></p>
                        <p><strong>Documento carregado:</strong> Contrato de prestação de serviços entre Empresa XYZ e Prestadora ABC</p>
                        <p><strong>Termo pesquisado:</strong> Jurisprudência sobre inadimplência contratual em serviços de saúde</p>
                        <p><strong>Data da pesquisa:</strong> 15/09/2024</p>
                        <p><strong>Principais resultados:</strong></p>
                        <p><strong>Caso:</strong> Empresa XYZ vs. Prestadora ABC – STJ, 2022.</p>
                        <p><strong>Relevância:</strong> O caso foi identificado como similar ao contrato em questão, envolvendo uma disputa sobre falhas na prestação de serviços de saúde.</p>
                        <p><strong>Decisão:</strong> Responsabilidade da prestadora de serviços foi reconhecida.</p>
                    </div>
                </div>
                <span className='txt-inferior'>Ir para Jurisearch</span>
            </div>
            <div className='jimmy-container home-container'>
                <span className='txt-superior'>Última interação com o Jimmy</span>
                <div className='container-detalhes'>
                    <span className='txt-interno'>Resumo de documento</span>
                </div>
                <span className='txt-inferior'>Ir para Jimmy</span>
            </div>
        </div>
    );
};

export default HomePage;
