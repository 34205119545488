import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import HomePage from './pages/HomePage';
import JimmyPage from './pages/Jimmy';
import CalendarioPage from './pages/Calendario';
import JurisprudenciaPage from './pages/Jurisprudencia';
import PerfilPage from './pages/Perfil';
import './App.css';

const App = () => {
    return (
        <div className='navbar-container'>
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/jimmy" element={<JimmyPage />} />
                    <Route path="/calendario" element={<CalendarioPage />} />
                    <Route path="/jurisprudencia" element={<JurisprudenciaPage />} />
                    <Route path="/perfil" element={<PerfilPage />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
